import axios from "@/axios";
import { uncalculableFrequencies } from "@/utils/consts";
import {
  formatDateYmd,
  getBookingServiceClientTotalPrice,
  getBookingServiceOwnerTotalPrice,
  getBookingServicePendingPrice,
} from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loading: false,
    booking: null,
    loadingClient: false,
    client: null,
    loadingOwner: false,
    owner: null,
    loadingDetails: false,
    details: null,
    loadingStats: false,
    stats: null,
    loadingGuests: false,
    guests: [],
    loadingReview: false,
    review: null,
    loadingServices: false,
    services: [],
    loadingClientPayments: false,
    clientPayments: [],
    loadingRates: false,
    rates: [],
    loadingOwnerRates: false,
    ownerRates: [],
    loadingComments: false,
    comments: [],
    loadingInvoices: false,
    invoices: [],
    loadingOwnerLiquidations: false,
    ownerLiquidations: [],
    loadingSpecialRequests: false,
    specialRequests: [],
    loadingLogs: false,
    logs: [],
    loadingIncidences: false,
    incidences: [],
  },
  getters: {
    loadingSomething(state) {
      return (
        state.loading ||
        state.loadingClient ||
        state.loadingOwner ||
        state.loadingDetails ||
        state.loadingStats ||
        state.loadingGuests ||
        state.loadingReview ||
        state.loadingServices ||
        state.loadingClientPayments ||
        state.loadingRates ||
        state.loadingOwnerRates ||
        state.loadingComments ||
        state.loadingSpecialRequests ||
        state.loadingLogs ||
        state.loadingIncidences
      );
    },
    loading(state) {
      return state.loading;
    },
    booking(state) {
      return state.booking || null;
    },
    contract(state, getters) {
      return getters.booking?.contract || null;
    },
    loadingDetails(state) {
      return state.loadingDetails;
    },
    details(state, getters) {
      return state.details || getters.booking?.details || null;
    },
    loadingStats(state) {
      return state.loadingStats;
    },
    stats(state) {
      return state.stats;
    },
    loadingClient(state) {
      return state.loadingClient;
    },
    client(state) {
      return state.client;
    },
    loadingOwner(state) {
      return state.loadingOwner;
    },
    owner(state) {
      return state.owner;
    },
    loadingGuests(state) {
      return state.loadingGuests;
    },
    guests(state) {
      return state.guests || [];
    },
    guestsAdultsCount(state, getters) {
      if (!getters.guests?.length) {
        return 0;
      }

      const today = new Date();

      return getters.guests.reduce((count, guest) => {
        if (!guest.birthDate) return count;

        const birthDate = new Date(guest.birthDate);
        const age = today.getFullYear() - birthDate.getFullYear();

        // Adjust age if the birthday hasn't occurred yet this year
        const isBirthdayPassed =
          today.getMonth() > birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

        const adjustedAge = isBirthdayPassed ? age : age - 1;

        return adjustedAge >= 18 ? count + 1 : count;
      }, 0);
    },
    guestsChildrenCount(state, getters) {
      if (!getters.guests?.length) {
        return 0;
      }

      const today = new Date();
      
      return getters.guests.reduce((count, guest) => {
        if (!guest.birthDate) return count;

        const birthDate = new Date(guest.birthDate);
        const age = today.getFullYear() - birthDate.getFullYear();

        // Adjust age if the birthday hasn't occurred yet this year
        const isBirthdayPassed =
          today.getMonth() > birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

        const adjustedAge = isBirthdayPassed ? age : age - 1;

        return (adjustedAge >= 2 && adjustedAge <= 17) ? count + 1 : count;
      }, 0);
    },
    guestsBabiesCount(state, getters) {
      if (!getters.guests?.length) {
        return 0;
      }

      const today = new Date();
      
      return getters.guests.reduce((count, guest) => {
        if (!guest.birthDate) return count;

        const birthDate = new Date(guest.birthDate);
        const age = today.getFullYear() - birthDate.getFullYear();

        // Adjust age if the birthday hasn't occurred yet this year
        const isBirthdayPassed =
          today.getMonth() > birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

        const adjustedAge = isBirthdayPassed ? age : age - 1;

        return adjustedAge <= 1 ? count + 1 : count;
      }, 0);
    },
    confirmedGuests(state, getters) {
      const { guests } = getters;

      if (!guests?.length) {
        return [];
      }

      return guests.filter((guest) => guest.confirmed);
    },
    reportableGuests(state, getters) {
      const { guests } = getters;

      if (!guests?.length) {
        return [];
      }

      return guests.filter((guest) => guest.age > 14);
    },
    loadingReview(state) {
      return state.loadingReview;
    },
    review(state) {
      return state.review || null;
    },
    loadingServices(state) {
      return state.loadingServices;
    },
    services(state) {
      return state.services || [];
    },
    unpaidServices(state, getters) {
      const { services, nights } = getters;

      if (!services?.length) {
        return [];
      }

      return services
        .filter((bookingService) => {
          return bookingService.chargable && bookingService.pvpPrice > 0;
        })
        .map((service) => ({
          ...service,
          pendingPrice: getBookingServicePendingPrice(service, nights),
        }))
        .filter((bookingService) => bookingService.pendingPrice > 0);
    },
    unpaidServicesTotalPending(state, getters) {
      if (!getters.unpaidServices?.length) return 0;
      // Note: in the unpaidServices getter, we already added a 'pendingPrice' field to each unpaid service,
      // that represents the amount that is still pending to be paid for that specific service. We use that calculated
      // field to calculate the total pending amount of all the unpaid services.
      return getters.unpaidServices.reduce(
        (unpaidServicesSum, service) =>
          unpaidServicesSum + (service.pendingPrice || 0),
        0
      );
    },
    chargableServices(state, getters) {
      const { services } = getters;

      if (!services?.length) {
        return [];
      }

      return services.filter((bookingService) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is not chargable
        if (
          bookingService.paymentFrequency &&
          uncalculableFrequencies.includes(bookingService.paymentFrequency)
        ) {
          return false;
        }

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is chargable
        if (!bookingService.service) {
          return bookingService.chargable;
        }

        // If the booking service have a related service, check if the booking service is chargable
        // and that the related service is not the security deposit
        return (
          bookingService.chargable &&
          bookingService.service?.code !== "SECURITY_DEPOSIT"
        );
      });
    },
    chargableServicesTotal(state, getters) {
      const { chargableServices, nights } = getters;

      if (!chargableServices?.length) {
        return 0;
      }

      return chargableServices.reduce((acc, chargableService) => {
        const servicePrice = getBookingServiceClientTotalPrice(
          chargableService,
          nights
        );
        return acc + (servicePrice || 0);
      }, 0);
    },
    unchargableServices(state, getters) {
      const { services } = getters;

      if (!services?.length) {
        return [];
      }

      return services.filter((bookingService) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is unchargable
        if (
          bookingService.paymentFrequency &&
          uncalculableFrequencies.includes(bookingService.paymentFrequency)
        ) {
          return true;
        }

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is not chargable
        if (!bookingService.service) {
          return !bookingService.chargable;
        }

        // If the booking service have a service related, check if the booking service is not chargable
        // and that the related service is not the security deposit
        return (
          !bookingService.chargable &&
          bookingService.service.code !== "SECURITY_DEPOSIT"
        );
      });
    },
    ownerChargableServices(state, getters) {
      const { services, nights } = getters;

      if (!services?.length) {
        return [];
      }

      return services.filter((bookingService) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is not chargable
        if (
          bookingService.paymentFrequency &&
          uncalculableFrequencies.includes(bookingService.paymentFrequency)
        ) {
          return false;
        }

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is chargable
        if (!bookingService.service) {
          return bookingService.chargable;
        }

        // If the booking service have a service related, check:
        //    - if the booking service is chargable,
        //    - the related service is not the security deposit
        //    - the booking service is not meant to be paid to the provider
        //    - the booking service has a defined owner price
        //    - the booking service is not a 3rd party service
        const bookingServicePrice = getBookingServiceOwnerTotalPrice(
          bookingService,
          nights
        );
        return (
          bookingService.chargable &&
          bookingService.service.type !== "3RD_PARTY_SERVICE" &&
          bookingService.service.code !== "SECURITY_DEPOSIT" &&
          bookingService.paymentTime !== "PROVIDER" &&
          bookingServicePrice
        );
      });
    },
    ownerChargableServicesTotal(state, getters) {
      const { ownerChargableServices, nights } = getters;

      if (!ownerChargableServices?.length) {
        return 0;
      }

      return ownerChargableServices.reduce((acc, chargableService) => {
        const servicePrice = getBookingServiceOwnerTotalPrice(
          chargableService,
          nights
        );
        return acc + (servicePrice || 0);
      }, 0);
    },
    ownerUnchargableServices(state, getters) {
      const { services, nights } = getters;

      if (!services?.length) {
        return [];
      }

      return services.filter((bookingService) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is unchargable
        if (
          bookingService.paymentFrequency &&
          uncalculableFrequencies.includes(bookingService.paymentFrequency)
        )
          return true;

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is not chargable
        if (!bookingService.service) return !bookingService.chargable;

        // If the booking service have a service related, check:
        //    - if the booking service is chargable,
        //    - the related service is not the security deposit
        //    - the booking service is not meant to be paid to the provider
        //    - the booking service has a defined owner price
        //    - the booking service is not a 3rd party service
        const bookingServicePrice = getBookingServiceOwnerTotalPrice(
          bookingService,
          nights
        );
        return (
          !bookingService.chargable &&
          bookingService.service.type !== "3RD_PARTY_SERVICE" &&
          bookingService.service.code !== "SECURITY_DEPOSIT" &&
          bookingService.paymentTime !== "PROVIDER" &&
          bookingServicePrice
        );
      });
    },
    accommodationExtras(state, getters) {
      const { services } = getters;

      if (!services?.length) {
        return [];
      }

      return services.filter((bookingService) => {
        return bookingService.service?.type === "ACCOMMODATION_EXTRA";
      });
    },
    loadingClientPayments(state) {
      return state.loadingClientPayments;
    },
    clientPayments(state) {
      if (!state.clientPayments?.length) {
        return [];
      }

      return state.clientPayments.filter(
        (clientPayment) => clientPayment.pvpAmount > 0
      );
    },
    hasAccountedClientPayments(state, getters) {
      if (!getters.clientPayments?.length) {
        return false;
      }
      return getters.clientPayments.some(
        (clientPayment) => clientPayment.status === "ACCOUNTED"
      );
    },
    clientRefunds(state) {
      if (!state.clientPayments?.length) {
        return [];
      }

      return state.clientPayments.filter(
        (clientPayment) => clientPayment.pvpAmount <= 0
      );
    },
    loadingRates(state) {
      return state.loadingRates;
    },
    rates(state) {
      return state.rates;
    },
    loadingOwnerRates(state) {
      return state.loadingOwnerRates;
    },
    ownerRates(state) {
      return state.ownerRates;
    },
    initialPaymentAmount(state, getters) {
      const {
        chargableServices,
        policy,
        subtotalDiscounted,
        isLastMinute,
        chargableServicesTotal,
      } = getters;

      const initialChargableServicesAmount = chargableServices.reduce(
        (acc, chargableService) => {
          if (chargableService.paymentTime !== "INITIAL_PAYMENT") return acc;
          return acc + (chargableService.pvpPrice || 0);
        },
        0
      );

      if (!policy) return subtotalDiscounted + initialChargableServicesAmount;

      if (isLastMinute) return subtotalDiscounted + chargableServicesTotal;

      const percentage = policy?.initialPaymentAmount || 100;

      return (
        subtotalDiscounted * (percentage / 100) + initialChargableServicesAmount
      );
    },
    initialPaymentPendingAmount(state, getters) {
      return getters.initialPaymentAmount - getters.charged + getters.refunded;
    },
    initialPaymentDueDate(state, getters) {
      if (!getters.booking?.date) return new Date();
      const bookingDate = getters.booking.date.split("T")[0];
      const dueDate = new Date(bookingDate);
      dueDate.setDate(dueDate.getDate() + 1);
      return dueDate;
    },
    finalPaymentAmount(state, getters) {
      return getters.total - getters.initialPaymentAmount;
    },
    finalPaymentPendingAmount(state, getters) {
      return getters.finalPaymentAmount - getters.charged + getters.refunded;
    },
    finalPaymentDueDate(state, getters) {
      if (!state.booking?.date) return new Date();

      if (!getters.policy) {
        const bookingDate = state.booking.date.split("T")[0];
        const dueDate = new Date(bookingDate);
        dueDate.setDate(dueDate.getDate() + 1);
        return dueDate;
      }

      const checkinDate = state.booking.checkin.split("T")[0];
      const dueDate = new Date(checkinDate);
      dueDate.setDate(
        dueDate.getDate() - getters.policy.finalPaymentLimit / 24
      );
      return dueDate;
    },
    subtotal(state, getters) {
      return getters.booking?.ratesPrice || 0;
    },
    subtotalDiscounted(state, getters) {
      const { subtotal, discountPrice } = getters;
      return subtotal - discountPrice;
    },
    nights(state, getters) {
      return getters.booking?.nights || null;
    },
    averageRatePerNight(state, getters) {
      const { subtotalDiscounted, nights } = getters;

      if (!subtotalDiscounted || !nights) return null;
      return subtotalDiscounted / getters.nights;
    },
    subtotalCharged(state, getters) {
      const { clientPayments } = getters;

      if (!clientPayments?.length) {
        return 0;
      }

      return clientPayments.reduce((acc, clientPayment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          ) &&
          clientPayment.scope === "BOOKING"
        ) {
          return acc + (clientPayment.pvpAmount || 0);
        }

        return acc;
      }, 0);
    },
    subtotalRefunded(state, getters) {
      const { clientRefunds } = getters;

      if (!clientRefunds?.length) {
        return 0;
      }

      return clientRefunds.reduce((acc, clientPayment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          ) &&
          clientPayment.scope === "BOOKING"
        ) {
          return acc + (clientPayment.pvpAmount || 0);
        }

        return acc;
      }, 0);
    },
    subtotalPending(state, getters) {
      const { subtotalDiscounted, subtotalCharged, subtotalRefunded } = getters;
      return subtotalDiscounted - subtotalCharged + subtotalRefunded;
    },
    ownerSubtotal(state, getters) {
      return getters.booking?.ownerPrice || 0;
    },
    averageOwnerRatePerNight(state, getters) {
      const { ownerSubtotal, nights } = getters;

      if (!ownerSubtotal || !nights) {
        return null;
      }

      return ownerSubtotal / nights;
    },
    agencySubtotal(state, getters) {
      const { subtotalDiscounted, ownerSubtotal } = getters;
      return subtotalDiscounted - ownerSubtotal;
    },
    discount(satate, getters) {
      return getters.booking?.discount || null;
    },
    discountPrice(satate, getters) {
      return getters.booking?.discountPrice || 0;
    },
    total(state, getters) {
      const { subtotalDiscounted, chargableServicesTotal } = getters;

      let total = 0;

      if (subtotalDiscounted > 0) {
        total += subtotalDiscounted;
      }
      if (chargableServicesTotal > 0) {
        total += chargableServicesTotal;
      }

      return total;
    },
    ownerTotal(state, getters) {
      const { ownerSubtotal, ownerChargableServicesTotal } = getters;

      let total = 0;

      if (ownerSubtotal > 0) {
        total += ownerSubtotal;
      }
      if (ownerChargableServicesTotal > 0) {
        total += ownerChargableServicesTotal;
      }

      return total;
    },
    charged(state, getters) {
      const { clientPayments } = getters;

      if (!clientPayments?.length) {
        return 0;
      }

      return clientPayments.reduce((acc, clientPayment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          ) &&
          clientPayment.scope !== "SECURITY_DEPOSIT"
        ) {
          return acc + (clientPayment.pvpAmount || 0);
        }
        return acc;
      }, 0);
    },
    refunded(state, getters) {
      const { clientRefunds } = getters;

      if (clientRefunds.length === 0) return 0;
      return clientRefunds.reduce((acc, clientPayment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          ) &&
          clientPayment.scope !== "SECURITY_DEPOSIT"
        ) {
          return acc + (clientPayment.pvpAmount || 0);
        }
        return acc;
      }, 0);
    },
    pending(state, getters) {
      return getters.total - getters.charged - getters.refunded;
    },
    isLastMinute(state, getters) {
      const { booking, policy } = getters;

      if (!policy?.lastMinuteStart) {
        return false;
      }

      const checkinDate = new Date(booking.checkin.split("T")[0]);
      const bookingDate = new Date(booking.date.split("T")[0]);
      const hoursBetweenDates = Math.abs(checkinDate - bookingDate) / 36e5;
      return hoursBetweenDates <= policy.lastMinuteStart;
    },
    // ! Improved by AI
    securityDepositServices(state, getters) {
      const { services } = getters;

      return services.filter((bookingService) => {
        const { code } = bookingService.service || {};
        return code?.includes("SECURITY_DEPOSIT");
      });
    },
    // ! Improved by AI
    securityDepositChargableServices(state, getters) {
      const { securityDepositServices } = getters;
      return securityDepositServices.filter(({ chargable }) => chargable);
    },
    // ! Improved by AI
    hasSecurityDeposit(state, getters) {
      const { securityDepositServices } = getters;
      return !!securityDepositServices.length;
    },
    // ! Improved by AI
    hasChargableSecurityDeposit(state, getters) {
      const { securityDepositChargableServices } = getters;
      return !!securityDepositChargableServices.length;
    },
    // ! Improved by AI
    hasManagedSecurityDeposit(state, getters) {
      return getters.booking?.managedSecurityDeposit || false;
    },
    // ! Improved by AI
    securityDepositPrice(state, getters) {
      const { securityDepositServices } = getters;

      return securityDepositServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    // ! Improved by AI
    securityDepositPayments(state, getters) {
      const { clientPayments, securityDepositServices } = getters;

      if (!clientPayments?.length || !securityDepositServices?.length) {
        return [];
      }

      // Extract tourist tax service IDs
      const securityDepositServicesIds = securityDepositServices.map(
        (bookingService) => bookingService["@id"]
      );

      return clientPayments.filter((clientPayment) => {
        if (!clientPayment.bookingService) {
          return false;
        }

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        // Check if the booking service URI is in the list of tourist tax service IDs
        return securityDepositServicesIds.includes(bookingServiceUri);
      });
    },
    // ! Improved by AI
    securityDepositRefunds(state, getters) {
      const { clientRefunds, securityDepositServices } = getters;

      if (!clientRefunds?.length || !securityDepositServices?.length) {
        return [];
      }

      // Extract tourist tax service IDs
      const securityDepositServicesIds = securityDepositServices.map(
        (bookingService) => bookingService["@id"]
      );

      return clientRefunds.filter((clientPayment) => {
        if (!clientPayment.bookingService) {
          return false;
        }

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        // Check if the booking service URI is in the list of tourist tax service IDs
        return securityDepositServicesIds.includes(bookingServiceUri);
      });
    },
    // ! Improved by AI
    securityDepositChargedPrice(state, getters) {
      const { securityDepositPayments } = getters;

      if (!securityDepositPayments?.length) {
        return 0;
      }

      return securityDepositPayments.reduce(
        (acc, { status, pvpAmount = 0 }) =>
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            status
          )
            ? acc + pvpAmount
            : acc,
        0
      );
    },
    // ! Improved by AI
    securityDepositRefundedPrice(state, getters) {
      const { securityDepositRefunds } = getters;

      if (!securityDepositRefunds?.length) {
        return 0;
      }

      const total = securityDepositRefunds.reduce(
        (acc, { status, pvpAmount = 0 }) =>
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            status
          )
            ? acc + pvpAmount
            : acc,
        0
      );

      // As the sum of refunds will be negative, we want to return a positive amount
      // as a refunded price.
      return total ? -total : 0;
    },
    // ! Improved by AI
    securityDepositPendingPrice(state, getters) {
      const {
        securityDepositPrice,
        securityDepositChargedPrice,
        securityDepositRefundedPrice,
      } = getters;

      if (
        securityDepositPrice == null ||
        securityDepositPrice === 0 ||
        !isFinite(securityDepositPrice)
      ) {
        return null;
      }

      return (
        securityDepositPrice -
        securityDepositChargedPrice +
        securityDepositRefundedPrice
      );
    },
    // ! Improved by AI
    touristTaxServices(state, getters) {
      const { services } = getters;

      return services.filter((bookingService) => {
        const { code } = bookingService.service || {};
        return code?.includes("TOURIST_TAX");
      });
    },
    // ! Improved by AI
    touristTaxChargableServices(state, getters) {
      const { touristTaxServices } = getters;
      return touristTaxServices.filter(({ chargable }) => chargable);
    },
    // ! Improved by AI
    touristTaxOwnerChargableServices(state, getters) {
      const { touristTaxServices } = getters;
      return touristTaxServices.filter(({ chargable }) => !chargable);
    },
    // ! Improved by AI
    hasTouristTax(state, getters) {
      const { touristTaxServices } = getters;
      return !!touristTaxServices.length;
    },
    // ! Improved by AI
    hasChargableTouristTax(state, getters) {
      const { touristTaxChargableServices } = getters;
      return !!touristTaxChargableServices.length;
    },
    // ! Improved by AI
    hasOwnerChargableTouristTax(state, getters) {
      const { touristTaxOwnerChargableServices } = getters;
      return !!touristTaxOwnerChargableServices.length;
    },
    // ! Improved by AI
    touristTaxPrice(state, getters) {
      const { touristTaxServices } = getters;

      return touristTaxServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxCostPrice(state, getters) {
      const { touristTaxServices } = getters;

      return touristTaxServices.reduce(
        (acc, { costPrice = 0 }) => acc + costPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxChargablePrice(state, getters) {
      const { touristTaxChargableServices } = getters;

      return touristTaxChargableServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxChargableCostPrice(state, getters) {
      const { touristTaxChargableServices } = getters;

      return touristTaxChargableServices.reduce(
        (acc, { costPrice = 0 }) => acc + costPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxOwnerChargablePrice(state, getters) {
      const { touristTaxOwnerChargableServices } = getters;

      return touristTaxOwnerChargableServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxOwnerChargableCostPrice(state, getters) {
      const { touristTaxOwnerChargableServices } = getters;

      return touristTaxOwnerChargableServices.reduce(
        (acc, { costPrice = 0 }) => acc + costPrice,
        0
      );
    },
    // ! Improved by AI
    touristTaxPayments(state, getters) {
      const { clientPayments, touristTaxServices } = getters;

      if (!clientPayments?.length || !touristTaxServices?.length) {
        return [];
      }

      // Extract tourist tax service IDs
      const touristTaxServicesIds = touristTaxServices.map(
        (bookingService) => bookingService["@id"]
      );

      return clientPayments.filter((clientPayment) => {
        if (!clientPayment.bookingService) {
          return false;
        }

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        // Check if the booking service URI is in the list of tourist tax service IDs
        return touristTaxServicesIds.includes(bookingServiceUri);
      });
    },
    // ! Improved by AI
    touristTaxRefunds(state, getters) {
      const { clientRefunds, touristTaxServices } = getters;

      if (!clientRefunds?.length || !touristTaxServices?.length) {
        return [];
      }

      // Extract tourist tax service IDs
      const touristTaxServicesIds = touristTaxServices.map(
        (bookingService) => bookingService["@id"]
      );

      return clientRefunds.filter((clientPayment) => {
        if (!clientPayment.bookingService) {
          return false;
        }

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        // Check if the booking service URI is in the list of tourist tax service IDs
        return touristTaxServicesIds.includes(bookingServiceUri);
      });
    },
    // ! Improved by AI
    touristTaxChargedPrice(state, getters) {
      const { touristTaxPayments } = getters;

      if (!touristTaxPayments?.length) {
        return 0;
      }

      return touristTaxPayments.reduce(
        (acc, { status, pvpAmount = 0 }) =>
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            status
          )
            ? acc + pvpAmount
            : acc,
        0
      );
    },
    // ! Improved by AI
    touristTaxRefundedPrice(state, getters) {
      const { touristTaxRefunds } = getters;

      if (!touristTaxRefunds?.length) {
        return 0;
      }

      const total = touristTaxRefunds.reduce(
        (acc, { status, pvpAmount = 0 }) =>
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            status
          )
            ? acc + pvpAmount
            : acc,
        0
      );

      // As the sum of refunds will be negative, we want to return a positive amount
      // as a refunded price.
      return total ? -total : 0;
    },
    // ! Improved by AI
    touristTaxPendingPrice(state, getters) {
      const {
        touristTaxPrice,
        touristTaxChargedPrice,
        touristTaxRefundedPrice,
      } = getters;

      if (
        touristTaxPrice == null ||
        touristTaxPrice === 0 ||
        !isFinite(touristTaxPrice)
      ) {
        return null;
      }

      return touristTaxPrice - touristTaxChargedPrice + touristTaxRefundedPrice;
    },
    // ! Improved by AI
    transferServices(state, getters) {
      const { services } = getters;

      return services.filter((bookingService) => {
        const { code } = bookingService.service || {};
        return code?.includes("TRANSFER");
      });
    },
    // ! Improved by AI
    hasTransfer(state, getters) {
      const { transferServices } = getters;
      return !!transferServices.length;
    },
    // ! Improved by AI
    transferPrice(state, getters) {
      const { transferServices } = getters;

      return transferServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    // ! Improved by AI
    carRentalServices(state, getters) {
      const { services } = getters;

      return services.filter((bookingService) => {
        const { code } = bookingService.service || {};
        return code?.includes("CAR_RENTAL");
      });
    },
    // ! Improved by AI
    hasCarRental(state, getters) {
      const { carRentalServices } = getters;
      return !!carRentalServices.length;
    },
    // ! Improved by AI
    carRentalPrice(state, getters) {
      const { carRentalServices } = getters;

      return carRentalServices.reduce(
        (acc, { pvpPrice = 0 }) => acc + pvpPrice,
        0
      );
    },
    babyChairs(state, getters) {
      return getters.details?.babyChairs || 0;
    },
    babyCots(state, getters) {
      return getters.details?.babyCots || 0;
    },
    loadingComments(state) {
      return state.loadingComments;
    },
    comments(state) {
      return state.comments || [];
    },
    loadingInvoices(state) {
      return state.loadingInvoices;
    },
    invoices(state) {
      return state.invoices || [];
    },
    loadingOwnerLiquidations(state) {
      return state.loadingOwnerLiquidations;
    },
    ownerLiquidations(state) {
      return state.ownerLiquidations || [];
    },
    loadingSpecialRequests(state) {
      return state.loadingSpecialRequests;
    },
    specialRequests(state) {
      return state.specialRequests || [];
    },
    loadingLogs(state) {
      return state.loadingLogs;
    },
    logs(state) {
      return state.logs || [];
    },
    loadingIncidences(state) {
      return state.loadingIncidences;
    },
    incidences(state) {
      return state.incidences || [];
    },
    policy(state, getters) {
      return getters.booking?.policy || null;
    },
    stayPvpInvoicedPrice(state, getters) {
      const { invoices } = getters;

      if (!invoices?.length) {
        return 0;
      }

      return invoices.reduce((totalSum, invoice) => {
        const { invoiceLines } = invoice;

        if (!invoiceLines?.length) {
          return totalSum;
        }

        const invoiceLinesSum = invoiceLines.reduce((sum, invoiceLine) => {
          if (invoiceLine.type === "STAY_PVP")
            return sum + (invoiceLine.pvpPrice || 0);
          return sum;
        }, 0);

        return totalSum + invoiceLinesSum;
      }, 0);
    },
    stayPvpInvoicePendingPrice(state, getters) {
      return getters.subtotalDiscounted - getters.stayPvpInvoicedPrice;
    },
    isStayPvpInvoiced(state, getters) {
      return getters.stayPvpInvoicedPrice > 0;
    },
    isStayPvpFullyInvoiced(state, getters) {
      return getters.subtotalDiscounted === getters.stayPvpInvoicedPrice;
    },
    isStayPvpUnderInvoiced(state, getters) {
      return getters.stayPvpInvoicedPrice < getters.subtotalDiscounted;
    },
    isStayPvpOverInvoiced(state, getters) {
      return getters.stayPvpInvoicedPrice > getters.subtotalDiscounted;
    },
    stayComissionInvoicedPrice(state, getters) {
      const { invoices } = getters;

      if (!invoices?.length) {
        return 0;
      }

      return invoices.reduce((totalSum, invoice) => {
        const { invoiceLines } = invoice;

        if (!invoiceLines?.length) {
          return totalSum;
        }

        const invoiceLinesSum = invoiceLines.reduce((sum, invoiceLine) => {
          if (invoiceLine.type === "STAY_COMISSION") {
            return sum + (invoiceLine.pvpPrice || 0);
          }
          return sum;
        }, 0);

        return totalSum + invoiceLinesSum;
      }, 0);
    },
    stayComissionInvoicePendingPrice(state, getters) {
      return getters.agencySubtotal - getters.stayComissionInvoicedPrice;
    },
    isStayComissionInvoiced(state, getters) {
      return getters.stayComissionInvoicedPrice > 0;
    },
    isStayComissionFullyInvoiced(state, getters) {
      return getters.agencySubtotal === getters.stayComissionInvoicedPrice;
    },
    isStayComissionUnderInvoiced(state, getters) {
      return getters.stayComissionInvoicedPrice < getters.agencySubtotal;
    },
    isStayComissionOverInvoiced(state, getters) {
      return getters.stayComissionInvoicedPrice > getters.agencySubtotal;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.booking = null;
      state.loadingClient = false;
      state.client = null;
      state.loadingOwner = false;
      state.owner = null;
      state.loadingDetails = false;
      state.details = null;
      state.loadingStats = false;
      state.stats = null;
      state.loadingGuests = false;
      state.guests = [];
      state.loadingReview = false;
      state.review = null;
      state.loadingServices = false;
      state.services = [];
      state.loadingClientPayments = false;
      state.clientPayments = [];
      state.loadingRates = false;
      state.rates = [];
      state.loadingOwnerRates = false;
      state.ownerRates = [];
      state.loadingComments = false;
      state.comments = [];
      state.loadingInvoices = false;
      state.invoices = [];
      state.loadingOwnerLiquidations = false;
      state.ownerLiquidations = [];
      state.loadingSpecialRequests = false;
      state.specialRequests = [];
      state.loadingLogs = false;
      state.logs = [];
      state.loadingIncidences = false;
      state.incidences = [];
    },
    SET_LOADING_BOOKING(state, payload) {
      state.loading = payload;
    },
    SET_BOOKING(state, payload) {
      state.booking = payload;
    },
    DELETE_BOOKING(state, payload) {
      state.booking = null;
    },
    SET_LOADING_DETAILS(state, payload) {
      state.loadingDetails = payload;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
    SET_LOADING_STATS(state, payload) {
      state.loadingStats = payload;
    },
    SET_STATS(state, payload) {
      state.stats = payload;
    },
    SET_LOADING_CLIENT(state, payload) {
      state.loadingClient = payload;
    },
    SET_CLIENT(state, payload) {
      state.client = payload;
    },
    SET_LOADING_OWNER(state, payload) {
      state.loadingOwner = payload;
    },
    SET_OWNER(state, payload) {
      state.owner = payload;
    },
    SET_LOADING_GUESTS(state, payload) {
      state.loadingGuests = payload;
    },
    SET_GUESTS(state, payload) {
      state.guests = payload;
    },
    ADD_GUEST(state, guest) {
      state.guests.push(guest);
    },
    UPDATE_GUEST(state, guest) {
      const guestIndex = state.guests.findIndex((g) => g.uuid === guest.uuid);
      state.guests.splice(guestIndex, 1, guest);
    },
    REMOVE_GUEST(state, guestUuid) {
      const guestIndex = state.guests.findIndex((g) => g.uuid === guestUuid);
      state.guests.splice(guestIndex, 1);
    },
    SET_LOADING_REVIEW(state, payload) {
      state.loadingReview = payload;
    },
    SET_REVIEW(state, payload) {
      state.review = payload;
    },
    SET_LOADING_SERVICES(state, payload) {
      state.loadingServices = payload;
    },
    SET_SERVICES(state, payload) {
      state.services = payload;
    },
    ADD_SERVICE(state, service) {
      state.services.push(service);
    },
    UPDATE_SERVICE(state, service) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === service.uuid
      );
      state.services.splice(serviceIndex, 1, service);
    },
    REMOVE_SERVICE(state, serviceUuid) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === serviceUuid
      );
      state.services.splice(serviceIndex, 1);
    },
    SET_LOADING_CLIENT_PAYMENTS(state, payload) {
      state.loadingClientPayments = payload;
    },
    SET_CLIENT_PAYMENTS(state, payload) {
      state.clientPayments = payload;
    },
    ADD_CLIENT_PAYMENT(state, clientPayment) {
      state.clientPayments.push(clientPayment);
    },
    UPDATE_CLIENT_PAYMENT(state, clientPayment) {
      const clientPaymentIndex = state.clientPayments.findIndex(
        (cp) => cp.uuid === clientPayment.uuid
      );
      state.clientPayments.splice(clientPaymentIndex, 1, clientPayment);
    },
    REMOVE_CLIENT_PAYMENT(state, clientPaymentUuid) {
      const clientPaymentIndex = state.clientPayments.findIndex(
        (cp) => cp.uuid === clientPaymentUuid
      );
      state.clientPayments.splice(clientPaymentIndex, 1);
    },
    SET_LOADING_RATES(state, payload) {
      state.loadingRates = payload;
    },
    SET_RATES(state, payload) {
      state.rates = payload;
    },
    SET_LOADING_OWNER_RATES(state, payload) {
      state.loadingOwnerRates = payload;
    },
    SET_OWNER_RATES(state, payload) {
      state.ownerRates = payload;
    },
    SET_BOOKING_OWNER_PRICE(state, payload) {
      if (!state.booking) return;
      state.booking.ownerPrice = payload;
    },
    SET_LOADING_COMMENTS(state, payload) {
      state.loadingComments = payload;
    },
    SET_COMMENTS(state, payload) {
      state.comments = payload;
    },
    ADD_COMMENT(state, comment) {
      state.comments.push(comment);
    },
    REMOVE_COMMENT(state, commentUuid) {
      const commentIndex = state.comments.findIndex(
        (comment) => comment.uuid === commentUuid
      );
      state.comments.splice(commentIndex, 1);
    },
    SET_LOADING_INVOICES(state, payload) {
      state.loadingInvoices = payload;
    },
    SET_INVOICES(state, payload) {
      state.invoices = payload;
    },
    ADD_INVOICE(state, invoice) {
      state.invoices.push(invoice);
    },
    REMOVE_INVOICE(state, invoiceUuid) {
      const invoiceIndex = state.invoices.findIndex(
        (invoice) => invoice.uuid === invoiceUuid
      );
      if (invoiceIndex !== -1) state.invoices.splice(invoiceIndex, 1);
    },
    SET_LOADING_OWNER_LIQUIDATIONS(state, payload) {
      state.loadingOwnerLiquidations = payload;
    },
    SET_OWNER_LIQUIDATIONS(state, payload) {
      state.ownerLiquidations = payload;
    },
    ADD_OWNER_LIQUIDATION(state, ownerLiquidation) {
      state.ownerLiquidations.push(ownerLiquidation);
    },
    REMOVE_OWNER_LIQUIDATION(state, ownerLiquidationUuid) {
      const ownerLiquidationIndex = state.ownerLiquidations.findIndex(
        (ownerLiquidation) => ownerLiquidation.uuid === ownerLiquidationUuid
      );
      if (ownerLiquidationIndex !== -1)
        state.ownerLiquidations.splice(ownerLiquidationIndex, 1);
    },
    SET_LOADING_SPECIAL_REQUESTS(state, payload) {
      state.loadingSpecialRequests = payload;
    },
    SET_SPECIAL_REQUESTS(state, payload) {
      state.specialRequests = payload;
    },
    ADD_SPECIAL_REQUEST(state, specialRequest) {
      state.specialRequests.push(specialRequest);
    },
    REMOVE_SPECIAL_REQUEST(state, specialRequestUuid) {
      const specialRequestIndex = state.specialRequests.findIndex(
        (com) => com.uuid === specialRequestUuid
      );
      state.specialRequests.splice(specialRequestIndex, 1);
    },
    SET_LOADING_LOGS(state, payload) {
      state.loadingLogs = payload;
    },
    SET_LOGS(state, payload) {
      state.logs = payload;
    },
    ADD_LOG(state, log) {
      state.logs.push(log);
    },
    REMOVE_LOG(state, logUuid) {
      const logIndex = state.logs.findIndex((item) => item.uuid === logUuid);
      state.logs.splice(logIndex, 1);
    },
    SET_LOADING_INCIDENCES(state, payload) {
      state.loadingIncidences = payload;
    },
    SET_INCIDENCES(state, payload) {
      state.incidences = payload;
    },
    ADD_INCIDENCE(state, incidence) {
      state.incidences.push(incidence);
    },
    ADD_INCIDENCE_COMMENT(state, incidenceComment) {
      const incidenceIndex = state.incidences.findIndex(
        (incidence) => incidence.uuid === incidenceComment.incidence.uuid
      );
      state.incidences[incidenceIndex].comments.push(incidenceComment);
    },
    REMOVE_INCIDENCE_COMMENT(state, incidenceComment) {
      const incidenceIndex = state.incidences.findIndex(
        (incidence) => incidence.uuid === incidenceComment.incidence.uuid
      );
      const commentIndex = state.incidences[incidenceIndex].comments.findIndex(
        (comment) => comment.uuid === incidenceComment.uuid
      );
      state.incidences[incidenceIndex].comments.splice(commentIndex, 1);
    },
    UPDATE_INCIDENCE(state, updatedIncidence) {
      const incidenceIndex = state.incidences.findIndex(
        (incidence) => incidence.uuid === updatedIncidence.uuid
      );
      state.incidences.splice(incidenceIndex, 1, updatedIncidence);
    },
    REMOVE_INCIDENCE(state, incidenceUuid) {
      const incidenceIndex = state.incidences.findIndex(
        (incidence) => incidence.uuid === incidenceUuid
      );
      state.incidences.splice(incidenceIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchBooking({ commit }, bookingUuid) {
      commit("SET_LOADING_BOOKING", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}`)
          .then((response) => {
            commit("SET_BOOKING", response.data);
            // The promise returns the booking data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_BOOKING", false));
      });
    },
    async fetchBookingByLocalizator({ commit }, localizator) {
      commit("SET_LOADING_BOOKING", true);

      const bookingUri = await axios
        .get(`/bookings?localizator=${localizator}`)
        .then((response) => {
          if (response.data["hydra:totalItems"] > 0)
            return response.data["hydra:member"][0]["@id"];
          return null;
        })
        .catch(() => null);

      return new Promise((resolve, reject) => {
        if (!localizator)
          reject(
            new Error(
              "[fetchBookingByLocalizator] localizator is null or empty"
            )
          );

        if (!bookingUri) {
          commit("SET_LOADING_BOOKING", false);
          reject(
            new Error(
              "[fetchBookingByLocalizator] the booking for the given localizator does not exist"
            )
          );
        }

        axios
          .get(bookingUri)
          .then((response) => {
            commit("SET_BOOKING", response.data);
            resolve(response.data);
          })
          .catch(() => {
            // TODO: Log error in Sentry
            reject(
              new Error(
                "[fetchBookingByLocalizator] error while fetching the booking data"
              )
            );
          })
          .finally(() => commit("SET_LOADING_BOOKING", false));
      });
    },
    updateBooking({ commit }, booking) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/bookings/${booking.uuid}`, booking, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_BOOKING", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteBooking({ commit }, bookingIri) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/delete_booking`, { booking: bookingIri })
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              // TODO: delete booking from all stores
              // commit("bookings/REMOVE_BOOKING", bookingUuid, { root: true });
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete de booking")
            )
          );
      });
    },
    importBooking({ commit }, bookingId) {
      return new Promise((resolve, reject) => {
        if (!bookingId) reject(new Error("Missing mandatory parameter"));

        const url = `/cm/import-booking/${bookingId}`;
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("bookings/ADD_BOOKING", response.data.booking, {
                root: true,
              });
              resolve(response.data.booking);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchDetails({ commit }, bookingUuid) {
      commit("SET_LOADING_DETAILS", true);
      return new Promise((resolve, reject) => {
        if (!bookingUuid)
          reject(new Error("The booking uuid parameter is mandatory"));
        axios
          .get(`/bookings/${bookingUuid}/details?pagination=false`)
          .then((response) => {
            commit("SET_DETAILS", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_DETAILS", false));
      });
    },
    updateDetails({ commit }, details) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_details/${details.uuid}`, details, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_DETAILS", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchStats({ commit }, bookingUuid) {
      commit("SET_LOADING_STATS", true);
      return new Promise((resolve, reject) => {
        if (!bookingUuid)
          reject(new Error("The booking uuid parameter is mandatory"));
        axios
          .get(`/bookings/${bookingUuid}/stats?pagination=false`)
          .then((response) => {
            commit("SET_STATS", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_STATS", false));
      });
    },
    updateStats({ commit }, stats) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_stats/${stats.uuid}`, stats, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_STATS", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchClient({ commit }, clientUuid) {
      if (!clientUuid) return null;
      commit("SET_LOADING_CLIENT", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/clients/${clientUuid}`)
          .then((response) => {
            commit("SET_CLIENT", response.data);
            // The promise returns the client data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_CLIENT", false));
      });
    },
    updateClient({ commit }, client) {
      return new Promise((resolve, reject) => {
        if (!client) {
          reject(new Error("The client data can not be null or empty"));
          return;
        }

        axios
          .patch(`/clients/${client.uuid}`, client, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CLIENT", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchOwner({ commit }, ownerUuid) {
      return new Promise((resolve, reject) => {
        if (!ownerUuid) {
          reject(new Error("The ownerUuid is null or empty"));
          return;
        }

        commit("SET_LOADING_OWNER", true);

        axios
          .get(`/owners/${ownerUuid}`)
          .then((response) => {
            commit("SET_OWNER", response.data);
            // The promise returns the owner data
            resolve(response.data);
          })
          .catch((error) => {
            reject(new Error(error.message));
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_OWNER", false));
      });
    },
    fetchGuests({ commit }, bookingUuid) {
      commit("SET_LOADING_GUESTS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/guests?pagination=false`)
          .then((response) => {
            commit("SET_GUESTS", response.data["hydra:member"]);
            // The promise returns the client data
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_GUESTS", false));
      });
    },
    addGuest({ commit }, guest) {
      return new Promise((resolve, reject) => {
        axios
          .post("/guests", guest)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_GUEST", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateGuest({ commit }, guest) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/guests/${guest.uuid}`, guest, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_GUEST", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteGuest({ commit }, guestUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/guests/${guestUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_GUEST", guestUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete de booking")
            )
          );
      });
    },
    fetchReview({ commit }, reviewUuid) {
      if (!reviewUuid) return null;
      commit("SET_LOADING_REVIEW", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/reviews/${reviewUuid}`)
          .then((response) => {
            commit("SET_REVIEW", response.data);
            // The promise returns the review data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_REVIEW", false));
      });
    },
    addReview({ commit }, review) {
      return new Promise((resolve, reject) => {
        axios
          .post("/reviews", review)
          .then((response) => {
            if (response.status === 201) {
              commit("SET_REVIEW", response.data);
              resolve();
            } else {
              // TODO: Log error in Sentry
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateReview({ commit }, review) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/reviews/${review.uuid}`, review, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_REVIEW", response.data);
              resolve();
            } else {
              reject(new Error("axios updateReview error"));
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteReview({ commit }, reviewUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/reviews/${reviewUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("SET_REVIEW", null);
              resolve();
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the review")
            )
          );
      });
    },
    fetchServices({ commit }, bookingUuid) {
      commit("SET_LOADING_SERVICES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/services?pagination=false`)
          .then((response) => {
            commit("SET_SERVICES", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_SERVICES", false));
      });
    },
    addService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_services", service)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_SERVICE", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_services/${service.uuid}`, service, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_SERVICE", response.data);
              resolve();
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteService({ commit }, serviceUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_services/${serviceUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_SERVICE", serviceUuid);
              resolve();
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the service")
            )
          );
      });
    },
    fetchClientPayments({ commit }, bookingUuid) {
      commit("SET_LOADING_CLIENT_PAYMENTS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/client_payments?pagination=false`)
          .then((response) => {
            commit("SET_CLIENT_PAYMENTS", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_CLIENT_PAYMENTS", false));
      });
    },
    addClientPayment({ commit }, clientPayment) {
      return new Promise((resolve, reject) => {
        axios
          .post("/client_payments", clientPayment)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_CLIENT_PAYMENT", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateClientPayment({ commit }, clientPayment) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/client_payments/${clientPayment.uuid}`, clientPayment, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_CLIENT_PAYMENT", response.data);
              resolve();
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteClientPayment({ commit }, paymentUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/client_payments/${paymentUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_CLIENT_PAYMENT", paymentUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete de client payment"
              )
            )
          );
      });
    },
    fetchRates({ commit }, bookingUuid) {
      commit("SET_LOADING_RATES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/rates?pagination=false`)
          .then((response) => {
            commit("SET_RATES", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_RATES", false));
      });
    },
    setRates(context, config) {
      return new Promise((resolve, reject) => {
        if (
          config.booking &&
          config.checkin &&
          config.checkout &&
          config.rates.length > 0
        ) {
          axios
            .post("/update_booking_rates", {
              booking: config.booking,
              checkin: formatDateYmd(config.checkin),
              checkout: formatDateYmd(config.checkout),
              rates: config.rates,
              price: config.price,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    fetchOwnerRates({ commit }, bookingUuid) {
      commit("SET_LOADING_OWNER_RATES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/owner_rates?pagination=false`)
          .then((response) => {
            commit("SET_OWNER_RATES", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_OWNER_RATES", false));
      });
    },
    setOwnerRates(_, config) {
      return new Promise((resolve, reject) => {
        if (config.booking && config.rates.length > 0) {
          axios
            .post("/post_booking_owner_price", {
              booking: config.booking,
              rates: config.rates,
              price: config.price,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    updateOwnerRates(_, config) {
      return new Promise((resolve, reject) => {
        if (config.booking) {
          axios
            .post("/update_booking_owner_price", {
              booking: config.booking,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    fetchComments({ commit }, bookingUuid) {
      commit("SET_LOADING_COMMENTS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/comments?pagination=false`)
          .then((response) => {
            commit("SET_COMMENTS", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_COMMENTS", false));
      });
    },
    addComment({ commit }, comment) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_comments", comment)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_COMMENT", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteComment({ commit }, commentUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_comments/${commentUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_COMMENT", commentUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the comment")
            )
          );
      });
    },
    fetchInvoices({ commit }, bookingUuid) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING_INVOICES", true);
        axios
          .get(`/bookings/${bookingUuid}/invoices?pagination=false`)
          .then((response) => {
            commit("SET_INVOICES", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => {
            commit("SET_LOADING_INVOICES", false);
          });
      });
    },
    addInvoice({ commit }, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .post("/invoices", invoice)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INVOICE", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchOwnerLiquidations({ commit }, bookingUuid) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING_OWNER_LIQUIDATIONS", true);
        axios
          .get(`/bookings/${bookingUuid}/owner_liquidations?pagination=false`)
          .then((response) => {
            commit("SET_OWNER_LIQUIDATIONS", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => {
            commit("SET_LOADING_OWNER_LIQUIDATIONS", false);
          });
      });
    },
    addOwnerLiquidation({ commit }, ownerLiquidation) {
      return new Promise((resolve, reject) => {
        axios
          .post("/owner_liquidations", ownerLiquidation)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_OWNER_LIQUIDATION", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    // CONTINUAR AQUI
    fetchSpecialRequests({ commit }, bookingUuid) {
      commit("SET_LOADING_SPECIAL_REQUESTS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/special_requests?pagination=false`)
          .then((response) => {
            commit("SET_SPECIAL_REQUESTS", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_SPECIAL_REQUESTS", false));
      });
    },
    addSpecialRequest({ commit }, specialRequest) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_special_requests", specialRequest)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_SPECIAL_REQUEST", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteSpecialRequest({ commit }, specialRequestUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_special_requests/${specialRequestUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_SPECIAL_REQUEST", specialRequestUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete the special request"
              )
            )
          );
      });
    },
    fetchLogs({ commit }, bookingUuid) {
      commit("SET_LOADING_LOGS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/logs?pagination=false`)
          .then((response) => {
            commit("SET_LOGS", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_LOGS", false));
      });
    },
    addLog({ commit, rootGetters }, log) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_logs", {
            ...log,
            data: {
              ...log.data,
              adminUser: rootGetters["auth/loggedUserEmail"],
            },
          })
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_LOG", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteLog({ commit }, logUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_logs/${logUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_LOG", logUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the log")
            )
          );
      });
    },
    fetchIncidences({ commit }, bookingUuid) {
      commit("SET_LOADING_INCIDENCES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/${bookingUuid}/incidences?pagination=false`)
          .then((response) => {
            commit("SET_INCIDENCES", response.data["hydra:member"]);
            resolve();
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_INCIDENCES", false));
      });
    },
    addIncidence({ commit }, incidence) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_incidences", incidence)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INCIDENCE", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    addIncidenceComment({ commit }, incidenceComment) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_incidence_comments", incidenceComment)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INCIDENCE_COMMENT", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateIncidence({ commit }, incidence) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_incidences/${incidence.uuid}`, incidence, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_INCIDENCE", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteIncidence({ commit }, incidenceUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_incidences/${incidenceUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_INCIDENCE", incidenceUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the comment")
            )
          );
      });
    },
    deleteIncidenceComment({ commit }, comment) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_incidence_comments/${comment.uuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_INCIDENCE_COMMENT", comment);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the comment")
            )
          );
      });
    },
  },
};
